<template>
 <div id="app" class="flex flex-col min-h-screen overflow-x-hidden">
    <!-- Only include topNav when needed -->
    <template v-if="showNavigation">
      <topNav class="fixed top-0 left-0 right-0 z-50" />
      <main class="flex-1 pt-16 sm:pt-20 pb-20">
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </main>
    </template>

    <!-- Render without navigation spacing when nav is not needed -->
    <template v-else>
      <main class="flex-1 pb-20">
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </main>
    </template>

    <!-- WhatsApp Float Button - shown on most pages -->
    <WhatsAppFloat v-if="showWhatsAppFloat" />

    <!-- CallMeBanner transition -->
    <transition
      enter-active-class="transform transition ease-out duration-300"
      enter-from-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transform transition ease-in duration-300"
      leave-from-class="translate-x-0"
      leave-to-class="translate-x-full">
      <div v-if="setCallMeBanner" class="z-50 fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 bg-leeonDarkBlue bg-opacity-50 transition-opacity"></div>

        <div class="fixed inset-y-0 right-0 flex max-w-full">
          <div class="w-screen max-w-md sm:max-w-lg">
            <div class="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
              <!-- Header -->
              <div class="sticky top-0 z-10 bg-gradient-to-r from-leeonPink to-leeonBlue px-4 py-3 sm:px-6">
                <div class="flex items-center justify-between">
                  <h2 class="text-xl font-semibold text-white">Kontakt</h2>
                  <button @click="callMeBanner"
                    class="rounded-md text-white hover:text-leeonPink20 focus:outline-none focus:ring-2 focus:ring-leeonPink20">
                    <span class="sr-only">Schließen</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>

              <!-- Content -->
              <div class="relative flex-1 px-4 py-6 sm:px-6">
                <!-- Profile Image -->
                <div class="relative h-48 w-full overflow-hidden rounded-lg shadow-md">
                  <img
                    loading="lazy"
                    class="h-full w-full object-cover"
                    src="./assets/images/lisa_sm.jpg"
                    alt="Lisa Fay Profile"
                  />
                </div>

                <!-- Profile Info -->
                <div class="mt-6">
                  <div class="flex items-center">
                    <h3 class="text-2xl font-bold text-leeonDarkBlue">Lisa Fay</h3>
                    <span class="ml-2.5 flex-shrink-0 relative">
                      <span class="absolute flex h-3 w-3">
                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-leeonPink40 opacity-75"></span>
                        <span class="relative inline-flex rounded-full h-3 w-3 bg-leeonPink"></span>
                      </span>
                    </span>
                  </div>
                  <p class="mt-1 text-sm text-leeonDarkBlue60">lisa.fay@leecon.de</p>
                </div>

                <!-- Call to Action -->
                <div class="mt-8">
                  <h4 class="text-xl font-semibold text-red-150 mb-4">
                    Unser kostenloser Rückrufservice!
                  </h4>

                  <router-link
                    to="/booking"
                    @click="callMeBanner"
                    class="block w-full">
                    <button class="w-full flex items-center justify-center space-x-2 px-4 py-3 border-2 border-blue-150 text-blue-150 rounded-lg hover:bg-blue-150 hover:text-white transition-colors duration-200">
                      <i class="far fa-calendar-alt text-xl"></i>
                      <span>Jetzt Termin vereinbaren!</span>
                    </button>
                  </router-link>
                </div>

                <!-- Contact Details -->
                <div class="mt-8 space-y-6">
                  <div class="rounded-lg bg-leeonBlue20 p-4">
                    <h5 class="text-sm font-medium text-leeonBlue mb-3">Bildungsberatung</h5>
                    <div class="space-y-2 text-sm text-leeonDarkBlue">
                      <p><span class="font-medium">E-Mail:</span> <a href="mailto:beratung@a-leecon.de" class="text-leeonBlue hover:underline">beratung@a-leecon.de</a></p>
                      <p><span class="font-medium">Telefon:</span> 0800 0003704</p>
                    </div>
                  </div>

                  <!-- WhatsApp Contact Button -->
                  <div class="rounded-lg bg-green-50 border border-green-200 p-4">
                    <div class="flex items-center justify-between">
                      <div>
                        <h5 class="text-sm font-medium text-green-800 mb-1">Direkter Kontakt via WhatsApp</h5>
                        <p class="text-xs text-green-700">Schnelle & unkomplizierte Beratung</p>
                      </div>
                      <a href="http://wa.me/491722439836/?text=Hi%20advanced%20Leecon," 
                         class="inline-flex items-center px-3 py-2 bg-green-500 hover:bg-green-600 text-white font-medium rounded transition-colors duration-300">
                        <i class="fab fa-whatsapp mr-2"></i>
                        <span>Chat starten</span>
                      </a>
                    </div>
                  </div>

                  <div class="rounded-lg bg-leeonPink20 p-4">
                    <h5 class="text-sm font-medium text-leeonPink mb-3">Adresse</h5>
                    <address class="text-sm text-leeonDarkBlue not-italic">
                      A-Leecon GmbH<br />
                      Julius-Schickard-Str. 6<br />
                      68809 Neulußheim
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Footer - only show if navigation is shown -->
    <footer
      class="flex flex-wrap w-screen items-center justify-between px-4 py-2 bg-gradient-to-r from-[#e2007a] to-[#003882] fixed bottom-0 lg:z-[100]">
      <div class="hidden md:block w-full lg:w-auto lg:mr-6 mb-4 lg:mb-0 text-center font-extrabold text-white ml-12">
        © 2025 A-Leecon GmbH <span class="text-xs font-normal ml-2 mr-12">v
          <VersionIndicator />
        </span>
      </div>
      <SocialMediaLinks class="hidden lg:flex" />
      <div class="text-white text-md ml-4 hidden lg:block"><a href="/jetztwirdskrass" class="hover:text-leeonPink20 hover:underline transition-colors duration-300">#jetztwirdskrass</a></div>
      <div class="flex flex-grow items-center w-full lg:w-auto">

        <div class="mx-auto lg:mx-0 lg:ml-auto flex items-center space-x-8">
          <router-link class="inline-block mt-0 text-white hover:text-red-150"
            to="/standorte">Standorte</router-link>
          <router-link class="inline-block mt-0 text-white hover:text-red-150"
            to="/imprint">Impressum</router-link>
          <router-link to="/trainer/login">
            <a class="md:inline-block mt-0 text-white hover:text-red-150 hidden">TrainerCockpit</a>
          </router-link>
          <router-link to="/admin/login">
            <a class="md:inline-block mt-0 text-white hover:text-red-150 hidden">AdminCenter</a>
          </router-link>

        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import topNav from "./components/TopNavigation.vue";
import VersionIndicator from './components/VersionIndicator.vue'
import SocialMediaLinks from './components/SocialMediaLinks.vue'
import WhatsAppFloat from './components/WhatsAppFloat.vue'

export default {
  components: {
    topNav,
    VersionIndicator,
    SocialMediaLinks,
    WhatsAppFloat
  },

  data() {
    return {
      isOpen: false,
      dark: true,
    };
  },
  methods: {
    callMeBanner() {
      this.$store.commit("callMeBanner");
    },

  },



  mounted() {
    // Force recompute of showNavigation when window is resized
    window.addEventListener('resize', () => {
      this.$forceUpdate();
    });
  },

  beforeDestroy() {
    // Clean up the event listener
    window.removeEventListener('resize', () => {
      this.$forceUpdate();
    });
  },

  computed: {
    setCallMeBanner() {
      return this.$store.getters.callMeBanner;
    },
    showNavigation() {
      const currentRoute = this.$route;

      // First check if we're on the learning experience page
      if (currentRoute.path.includes('/portal/learning/')) {
        return false;
      }

      // Check if we're on a portal route and screen is medium or smaller
      if (currentRoute.path.startsWith('/portal')) {
        // Special case for angebot route - always show navigation
        if (currentRoute.name === 'Angebotsformular') {
          return true;
        }
        // For other portal routes, hide navigation
        return false;
      }

      // Check if route path starts with these prefixes
      if (currentRoute.path.startsWith('/admin') ||
         (currentRoute.path.startsWith('/trainer'))) {
        return false;
      }

      // List of specific routes where navigation should be hidden
      const hiddenNavRoutes = [
        "AdminDashboard",
        "NeuesAngebot",
        "UpdateCustomerData",
        "CustomerOverview",
        "Ablage",
        "Kostentraeger",
        "NewKostentraeger",
        "UpdateKostentraeger",
        "TeamPlaner",
        "MessageBoard",
        "Fertig",
        "Onboarding",
        "TrainerManagement",
        "AdminComposeMessage",
        "Learning"
      ];

      return !hiddenNavRoutes.includes(currentRoute.name);
    },
    showWhatsAppFloat() {
      const currentRoute = this.$route;
      
      // Hide WhatsApp button on admin and trainer routes
      if (currentRoute.path.startsWith('/admin') || 
          currentRoute.path.startsWith('/trainer') ||
          currentRoute.path.startsWith('/portal') ||
          currentRoute.path.startsWith('/chat') 
        ) {
        return false;
      }
      
      // List of additional routes where WhatsApp button should be hidden
      const hiddenWhatsAppRoutes = [
        "Learning",
        "CustomerDashboard",
       "CourseProgress"
      ];
      
      return !hiddenWhatsAppRoutes.includes(currentRoute.name);
    }
  },
};
</script>

<style>
#app {
  position: relative;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom); /* Add safe area for mobile devices */
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Only show scrollbar on main content */
main::-webkit-scrollbar {
  display: block;
  width: 8px;
}

main::-webkit-scrollbar-track {
  background: #f1f1f1;
}

main::-webkit-scrollbar-thumb {
  background: #0094da;
  border-radius: 4px;
}

main::-webkit-scrollbar-thumb:hover {
  background: #007bb8;
}

/* Add this to ensure the footer doesn't cause horizontal scroll */
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 40; /* Ensure footer is above content but below modals */
  min-height: 3rem; /* Set a minimum height for the footer */
}

/* Add padding to the bottom of the main content area to prevent footer overlap */
main {
  padding-bottom: calc(3rem + env(safe-area-inset-bottom)); /* Use footer's minimum height */
  margin-bottom: 1rem; /* Add extra margin to ensure content doesn't touch the footer */
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}
</style>
