import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Statistics from "../views/Admin/Statistics.vue";
import StarterForm from '../components/Portal/StarterForm.vue'
import ResearchResults from '../views/ResearchResults.vue'

const routes = [
  { path: "/:catchAll(.*)", redirect: "/" },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/jetztwirdskrass",
    name: "JetztWirdsKrass",
    component: () => import("../views/JetztWirdsKrass.vue")
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: () => import("../views/Jobs.vue"),
  },
  {
    path: "/standorte",
    name: "Standorte",
    component: () => import("../views/Standorte.vue"),
  },
  {
    path: "/KI_Weiterbildung",
    name: "KI",
    component: () => import("../views/KI_Weiterbildung.vue"),
  },
  {
    path: "/coaching",
    name: "Coaching",
    component: () => import("../views/LeeonCoaching.vue"),
  },
  {
    path: "/science",
    name: "Science",
    component: () => import("../views/LeeonScience.vue"),
  },
  {
    path: "/futurelab",
    name: "FutureLab",
    component: () => import("../views/LeeonFutureLab.vue"),
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: () => import("../views/Kontakt.vue"),
  },
  {
    path: "/kursnet/:kursnetID",
    name: "KursnetLink",
    component: () => import("../views/KursnetDetail.vue"),
  },
  {
    path: "/vlog",
    name: "VLog",
    component: () => import("../views/VLog.vue"),
  },

  {
    path: "/booking",
    name: "Booking",
    component: () => import('../views/Booking.vue')
  },
  {
    path: "/notverified",
    name: "NotVerified",
    component: () =>
      import(/* webpackChunkName: "notverified" */ "../views/NotVerified.vue"),
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: () => import("../views/Imprint.vue"),
  },
  {
    path: "/dataprotection",
    name: "Dataprotection",
    component: () => import("../views/Dataprotection.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },

  {
    path: "/signup",
    name: "Signup",
    component: () => import("../views/Signup.vue"),
  },
  {
    path: "/employee-signin",
    name: "EmployeeSignIn",
    component: () => import("../views/Admin/EmployeeSignIn.vue"),
  },
  {
    path: "/link-microsoft-account",
    name: "LinkMicrosoftAccount",
    component: () => import("../views/Admin/LinkMicrosoftAccount.vue"),
  },
  {
    path: "/katalog",
    name: "Katalog",
    component: () => import("../views/SeminarKatalog.vue"),
  },
  {
    path: "/qcg",
    name: "QCG",
    component: () => import("../views/Landingpage_QCG.vue"),
  },
  {
    path: "/qcg-eu-ai-act",
    name: "EUAIAct",
    component: () => import("../views/LandingPages/QCG_AI_Act.vue"),
  },
  {
    path: "/faq",
    name: "FaQ",
    component: () => import("../views/FaQ.vue"),
  },
  {
    path: "/angebotsoptionen",
    name: "AngebotsOptionen",
    component: () => import("../views/AngebotsOptionen.vue"),
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: () => import("../views/Portal/PasswordEmail.vue"),
  },
  {
    path: "/:id/Seminar",
    name: "seminarDetail",
    component: () => import("../views/SeminarDetail.vue"),
    props: true,
  },
  {
    path: "/:id/Bundle",
    name: "bundleDetail",

    component: () => import("../views/BundleDetail.vue"),
  },
  {
    path: "/portal/angebot/:c_id",
    name: "Angebotsformular",

    component: () => import("../views/Portal/AngebotsForm.vue"),
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: "/portal/documents/:c_id",
    name: "Dokumente",

    component: () => import("../views/Portal/Dokumente.vue"),
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: "/portal/waitdocuments/:c_id",
    name: "WaitDocuments",

    component: () => import("../views/Portal/WaitDocuments.vue"),
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: "/portal/dashboard/:c_id",
    name: "CustomerDashboard",

    component: () => import("../views/Portal/Dashboard.vue"),
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },

  {
    path: "/portal/updatecustomer/:c_id",
    name: "UpdateData",

    component: () => import("../views/Portal/UpdateKundendaten.vue"),
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: "/portal/updatequote/:c_id",
    name: "UpdateAngebot",

    component: () => import("../views/Portal/UpdateAngebot.vue"),
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: "/portal/learning/:c_id/:courseID",
    name: "Learning",

    component: () => import("../views/Portal/LearningExperience.vue"),
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: "/portal/learningTeams/:c_id",
    name: "LearningTeams",

    component: () => import("../views/Portal/LearningTeams.vue"),
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },

  {
    path: "/admin/login",
    name: "AdminLogin",

    component: () => import("../views/Admin/Login.vue"),
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",

    component: () => import("../views/Admin/Dashboard.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/ablage",
    name: "Ablage",

    component: () => import("../views/Admin/Ablage.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/kostentraeger",
    name: "Kostentraeger",

    component: () => import("../views/Admin/Kostentraeger.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/messageboard",
    name: "MessageBoard",

    component: () => import("../views/Admin/MessageBoard.vue"),
  },
  {
    path: "/admin/onboarding",
    name: "Onboarding",

    component: () => import("../views/Admin/Onboarding.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/new_kostentraeger",
    name: "NewKostentraeger",

    component: () => import("../views/Admin/NewKostentraeger.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/update_kostentraeger/:c_id",
    name: "UpdateKostentraeger",

    component: () => import("../views/Admin/UpdateKostentraeger.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/newquote",
    name: "NeuesAngebot",

    component: () => import("../views/Admin/AngebotsForm.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/updatecustomer/:c_id",
    name: "UpdateCustomerData",

    component: () => import("../views/Admin/UpdateKundendaten.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/linkToCC/:c_id",
    name: "LinkToCC",

    component: () => import("../views/Admin/LinkToCC.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },

  {
    path: "/admin/overview/:c_id",
    name: "CustomerOverview",

    component: () => import("../views/Admin/Overview.vue"),
  },

  {
    path: "/:role/wait_teamplaner",
    name: "WaitTeamPlaner",

    component: () => import("../views/Admin/WaitTeamPlaner.vue"),
  },

  {
    path: "/admin/wait/:c_id",
    name: "Wait",

    component: () => import("../views/Admin/Wait.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/waitoverview/:c_id",
    name: "WaitCustomerOverview",

    component: () => import("../views/Admin/WaitOverview.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/:role/manager",
    name: "TeamManager",
    component: () => import("../views/Admin/TeamManager.vue"),
    meta: (route) => ({
      requiresAuth: true,
      role: route.params.role === "admin" ? "Admin" : "Trainer",
    }),
  },
  {
    path: "/:role/homework",
    name: "HomeworkManager",
    component: () => import("../views/Admin/HomeworkManager.vue"),
    meta: (route) => ({
      requiresAuth: true,
      role: route.params.role === "admin" ? "Admin" : "Trainer",
    }),
  },
  {
    path: "/:role/homework/:trainerId",
    name: "TrainerHomework",
    component: () => import("../views/Admin/TrainerHomework.vue"),
    meta: (route) => ({
      requiresAuth: true,
      role: route.params.role === "admin" ? "Admin" : "Trainer",
    }),
  },
  {
    path: "/:role/homework/:trainerId/:courseId",
    name: "CourseHomework",
    component: () => import("../views/Admin/CourseHomework.vue"),
    meta: (route) => ({
      requiresAuth: true,
      role: route.params.role === "admin" ? "Admin" : "Trainer",
    }),
  },
  {
    path: "/:role/homework/:trainerId/:courseId/:customerId",
    name: "ParticipantHomework",
    component: () => import("../views/Admin/ParticipantHomework.vue"),
    meta: (route) => ({
      requiresAuth: true,
      role: route.params.role === "admin" ? "Admin" : "Trainer",
    }),
  },
  {
    path: "/:role/planer",
    name: "TeamPlaner",
    component: () => import("../views/Admin/TeamPlaner.vue"),
    meta: (route) => ({
      requiresAuth: true,
      role: route.params.role === "admin" ? "Admin" : "Trainer",
    }),
  },

  {
    path: "/admin/waitdashboard",
    name: "WaitDashboard",

    component: () => import("../views/Admin/WaitDashboard.vue"),
    /*   meta: {
      requiresAdminAuth: true,
    }, */
  },
  {
    path: "/admin/waitcostcenter",
    name: "WaitKostentraeger",

    component: () => import("../views/Admin/WaitKostentraeger.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/trainer/dashboard",
    name: "TrainerDashboard",

    component: () => import("../views/Trainer/TrainerChat.vue"),
  },
  {
    path: "/trainer/login",
    name: "TrainerLogin",

    component: () => import("../views/Trainer/Login.vue"),
  },
  {
    path: "/trainer/overview/:c_id",
    name: "TrainerCustomerDetail",

    component: () => import("../views/Trainer/Overview.vue"),
    meta: {
      requiresTrainerAuth: true,
    },
  },
  {
    path: "/trainer/ablage",
    name: "TrainerAblage",

    component: () => import("../views/Admin/Ablage.vue"),
    meta: {
      requiresTrainerAuth: true,
    },
  },
  {
    path: "/coordinator/dashboard",
    name: "CoordinatorDashboard",

    component: () => import("../views/Coordinator/Dashboard.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/dp-agreement",
    name: "DataprotectionAgreement",
    component: () => import("../views/DataProtectionAgreement.vue"),
  },
  {
    path: "/admin/trainermanagement",
    name: "TrainerManagement",
    component: () => import("../views/Admin/TrainerManagement.vue"),
    meta: {
      requiresAdminAuth: true,
    },
  },
  {
    path: "/:role/compose-message",
    name: "AdminComposeMessage",
    component: () => import("../views/Admin/ComposeMessage.vue"),
    meta: (route) => ({
      requiresAuth: true,
      role: route.params.role === "admin" ? "Admin" : "Trainer",
    }),
  },
  {
    path: "/admin/statistics",
    name: "AdminStatistics",
    component: Statistics,
    meta: { requiresAdmin: true },
  },
  {
    path: "/ki-manager/wiedereinstieg-nach-elternzeit",
    name: "KIManagerWiedereinstieg",
    component: () => import("../views/KIManagerWiedereinstieg.vue"),
  },
  {
    path: "/ki-manager/absolventen",
    name: "Absolventen",
    component: () => import("../views/Absolventen.vue"),
  },
  {
    path: "/ki-manager/zweite-karriere",
    name: "ZweiteKarriere",
    component: () => import("../views/ZweiteKarriere.vue"),
  },
  {
    path: "/ki-manager/seminar",
    name: "LandingpageKI",
    component: () => import("../views/Landingpage_KI_Manager.vue"),
  },
  {
    path: '/ki-manager-kurstag',
    name: 'TuesdaySchedule',
    component: () => import('../views/TuesdaySchedule.vue')
  },
  {
    path: "/portal/support/:c_id",
    name: "SupportTickets",
    component: () => import("../views/Portal/SupportTickets.vue"),
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: "/portal/course-schedule/:c_id",
    name: "CourseSchedule",
    component: () => import("../views/Portal/CourseSchedule.vue"),
    meta: {
      requiresAuth: true,
      requiresEmailVerification: true,
    },
  },
  {
    path: "/admin/support-tickets",
    name: "AdminSupportTickets",
    component: () => import("../views/Admin/SupportTicketsAdmin.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/chat",
    name: "ChatBot",
    component: () => import("../views/ChatBot.vue"),
    meta: {
      title: "Leeon Chat - Dein KI-Karriereberater"
    }
  },
  {
    path: '/starter-form',
    name: 'StarterForm',
    component: StarterForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/coaching/kenntnisaufbau',
    name: 'KenntnisaufbauModule',
    component: () => import('../views/CoachingModules/KenntnisaufbauModule.vue')
  },
  {
    path: '/coaching/jobcoaching',
    name: 'JobcoachingModule',
    component: () => import('../views/CoachingModules/JobcoachingModule.vue')
  },
  {
    path: "/case-studies",
    name: "CaseStudies",
    component: () => import('../views/CaseStudies.vue')
  },
  {
    path: "/erfolge/:id",
    name: "ErfolgeDetail",
    component: () => import("../views/CaseStudyDetail.vue"),
    props: true
  },
  {
    path: "/eu_politik",
    name: "EU Politik",
    component: () => import("../views/PolititianQuestionnaire.vue"),
  },
  {
    path: '/eu-ai-act',
    name: 'ResearchResults',
    component: ResearchResults,
    meta: {
      title: 'Forschungsergebnisse: EU AI Act'
    }
  },
  {
    path: '/admin/business-customers',
    name: 'BusinessCustomers',
    component: () => import('../views/Admin/BusinessCustomers.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/:role/participant-management',
    name: 'ParticipantManagement',
    component: () => import('../views/Admin/ParticipantManagement.vue'),
    meta: (route) => ({
      requiresAuth: true,
      role: route.params.role === "admin" ? "Admin" : "Trainer",
    }),
  },
  {
    path: '/admin/accounting',
    name: 'Accounting',
    component: () => import('../views/Admin/Accounting.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/business-reports',
    name: 'BusinessReports',
    component: () => import('../views/Admin/BusinessReports.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/material-management',
    name: 'MaterialManagement',
    component: () => import('../views/Admin/MaterialManagement.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    document.getElementById('app').scrollTo(0, 0);
    return { top: 0, behavior: 'instant' }
  }
});

router.afterEach((to, from) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
});

export default router;
